import { createUseFetch, createUseFetchAction } from '@commonstock/client/src/react/createUseFetch'
import { HttpMethods, CacheType } from '@commonstock/client/src/constants'
import applyMe, { UserParams, UserParamsPaged } from '../utils/applyMe'
import { createUsePagedFetch, PagerOptions } from '@commonstock/client/src/react/createUsePagedFetch'

export type PrivateProfilePayload = {
  error: string
  user: HydratedUserProfile
}

export type HydratedUserProfile = {
  follow_status: FollowStatus
  name: string
  picture: string
  private: boolean
  username: string
  uuid: string
  verified: boolean
  followed_by_me?: boolean
  follower_assets?: number | null
  badges?: Badges
}

export enum FollowStatus {
  REQUESTED = 'REQUESTED',
  FOLLOWED = 'FOLLOWED',
  NOT_FOLLOWED = 'NOT_FOLLOWED'
}

export enum BadgeType {
  VERIFIED = 'verified',
  SHARED_BROKERAGE = 'shared_brokerage',
  TITAN = 'titan'
}

export type Badge = {
  title: string
  type: BadgeType
  description: string
  image: string
}

export type Badges = Badge[] | null

type ExternalProivder = {
  canonical: string
  is_stale: boolean
}
export type UserProfile = {
  user_id: number
  user_uuid: string
  email?: string
  name: string
  username: string
  picture: string
  picture_raw: string
  joined_at: string
  joined_at_timestamp: number
  followers: number
  following: number
  follower_assets: number | null
  followed_by_me: boolean
  follows_me: boolean
  bio: string
  blocked: boolean
  blocked_me: boolean
  tags: string[]
  private: boolean
  muted: boolean
  follow_status: FollowStatus
  portfolio_return: number | null
  verified: boolean
  twitter_username: string
  hidden: boolean
  pending_approval: boolean
  memo_alerts_enabled: boolean
  trade_alerts_enabled: boolean
  website: string
  metadata: { [key: string]: boolean } // the dictionary value is loosely defined in BE. Only known return type as of 6/18/21 is boolean.
  badges?: Badges
}

type BasicUserProfile = {
  bio: string
  follow_status: FollowStatus
  followed_by_me: boolean
  follower_assets: number | null
  hidden: boolean
  name: string
  picture: string
  private: boolean
  user_uuid: string
  username: string
  verified: boolean
  badges?: Badges
}

export type ExtendedProfile = UserProfile & {
  muted_users: string[]
  aggregate_portfolio_value: number
  external_providers: ExternalProivder[]
}

export function userProfileToHydratedProfile(p: UserProfile): HydratedUserProfile {
  return { ...p, uuid: p.user_uuid }
}

export const useGetProfile = createUseFetch<UserProfile, UserParams>({
  key: 'user-profile',
  path: ({ meta: { user_uuid: uuid } }) => `/profile/profiles/${uuid}`
})

export const useGetMyProfile = createUseFetch<ExtendedProfile>({
  key: 'my-profile',
  path: '/profile/my-profile',
  cacheTime: 2 * 60 * 1000
})

export const PROFILE_PATH = `/profile/username/profiles/`
export const useGetUsernameUuid = createUseFetch<UserProfile, { meta: { username: string } }>({
  key: 'profile-username',
  path: ({ meta: { username } }) => `${PROFILE_PATH}${username}`,
  cacheTime: 2 * 60 * 1000
})

export const useGetUsernameUuidAction = createUseFetchAction<UserProfile, { meta: { username: string } }>({
  key: 'profile-username-action',
  path: ({ meta: { username } }) => `${PROFILE_PATH}${username}`
})

export type PatchProfileParams = {
  meta: { uuid: string }
  json: Partial<ProfileData>
}
export const usePatchProfileAction = createUseFetchAction<void, PatchProfileParams>({
  key: 'patch-profile',
  path: ({ meta: { uuid } }) => `/profile/profiles/${uuid}`,

  method: HttpMethods.Patch
})

export const useGetRecommendedProfiles = createUseFetch<UserProfile[]>({
  key: 'recommend-profile',
  path: `/profile/recommend`
})

export const useGetRecommendedProfilesCurated = createUseFetch<UserProfile[], { query: { limit: number } }>({
  key: 'recommend-curated',
  path: `/profile/curated`
})

export type Channel = {
  name: string
  description: string
  private: boolean
  qty_members: number
}

export type ProfileData = {
  user_uuid: string
  name: string
  username: string
  bio: string
  tags: string[]
  metadata: { [key: string]: boolean }
  picture?: string
  private: boolean
  twitter_username: string
  website: string
}

export const useFollowProfile = createUseFetchAction<UserProfile, UserParams>({
  key: 'profile-follow',
  method: HttpMethods.Post,
  path: ({ meta: { user_uuid } }) => `/profile/profiles/${user_uuid}/follow`
})

export const useUnfollowProfile = createUseFetchAction<string, UserParams>({
  key: 'profile-unfollow',
  method: HttpMethods.Post,
  path: ({ meta: { user_uuid } }) => `/profile/profiles/${user_uuid}/unfollow`
})

export const useBlockProfile = createUseFetchAction<string, UserParams>({
  key: 'profile-block',
  method: HttpMethods.Post,
  path: ({ meta: { user_uuid } }) => `/profile/profiles/${user_uuid}/block`
})

export const useUnBlockProfile = createUseFetchAction<string, UserParams>({
  key: 'profile-unblock',
  method: HttpMethods.Post,
  path: ({ meta: { user_uuid } }) => `/profile/profiles/${user_uuid}/unblock`
})

export const useGetBlockedUsers = createUseFetch<UserProfile[], UserParams>({
  key: 'blocked-users',
  path: ({ meta: { user_uuid } }) => `/profile/profiles/${user_uuid}/blocked`
})

export const usePostRemoveFollower = createUseFetchAction<null, UserParams>({
  key: 'remove-follower',
  method: HttpMethods.Post,
  path: ({ meta: { user_uuid } }) => `/profile/profiles/${user_uuid}/remove-follower`
})

export const useGetPendingRequests = applyMe(
  createUseFetch<UserProfile[], UserParams>({
    key: 'user-follow-requests',
    path: ({ meta: { user_uuid } }) => `/profile/profiles/${user_uuid}/follow-requests`,
    refetchInterval: 30000
  })
)

type FollowRequestParams = {
  meta: {
    user_uuid: string
    follower_uuid: string
  }
}
export const useAcceptFollowRequest = createUseFetchAction<string, FollowRequestParams>({
  key: 'profile-accept-request',
  method: HttpMethods.Post,
  path: ({ meta: { user_uuid, follower_uuid } }) =>
    `/profile/profiles/${user_uuid}/follow-requests/${follower_uuid}/accept`
})

export const useRejectFollowRequest = createUseFetchAction<string, FollowRequestParams>({
  key: 'profile-reject-request',
  method: HttpMethods.Post,
  path: ({ meta: { user_uuid, follower_uuid } }) =>
    `/profile/profiles/${user_uuid}/follow-requests/${follower_uuid}/reject`
})

const pagerOptions: PagerOptions<UserProfile[], any> = {
  getCursor: (_page, resource) => {
    let list = resource?.success?.payload
    let last = list && list[list.length - 1]
    // @NOTE: All trades except for portfolio page, should have privacy 'on'
    return { query: { limit: 10, last_uuid: last?.user_uuid } }
  },
  isTerminal: ({ success }) => !!(success && success.payload?.length === 0)
}
export const useFollowing = createUsePagedFetch<UserProfile[], UserParamsPaged>(
  {
    key: 'get-following',
    path: ({ meta: { user_uuid } }) => `/profile/profiles/${user_uuid}/following`,
    cache: CacheType.Disk
  },
  pagerOptions
)

export const useFollowers = createUsePagedFetch<UserProfile[], UserParamsPaged>(
  {
    key: 'get-followers',
    path: ({ meta: { user_uuid } }) => `/profile/profiles/${user_uuid}/followers`
  },
  pagerOptions
)

export type CheckUsernamePayload = {
  available: boolean
  error: string
  username: string
  valid: boolean
}
type CheckUsernameParams = {
  meta: {
    username: string
  }
}

export const useCheckUsername = createUseFetchAction<CheckUsernamePayload, CheckUsernameParams>({
  key: 'check-username',
  path: ({ meta: { username } }) => `/profile/check-username/${username}`
})

type QueryProfilesParams = {
  meta: {
    search: string
  }
}

export const BasicProfileToHydratedProfile = (p: BasicUserProfile): HydratedUserProfile => ({
  ...p,
  uuid: p.user_uuid
})

export const useQueryVisibleProfiles = createUseFetch<BasicUserProfile[], QueryProfilesParams>({
  key: 'query-profile-visible',
  path: ({ meta: { search } }) => `/profile/search/profiles/public-or-followed/${search}`
})

export const useProfileSearch = createUseFetch<BasicUserProfile[], QueryProfilesParams>({
  key: 'query-profile',
  path: ({ meta: { search } }) => `/profile/search/profiles/all/${search}`
})

export const useProfileSearchAction = createUseFetchAction<BasicUserProfile[], QueryProfilesParams>({
  key: 'query-profile-action',
  path: ({ meta: { search } }) => `/profile/search/profiles/all/${search}`
})

export const useMuteUserAction = createUseFetchAction<string, UserParams>({
  key: 'mute-user',
  path: ({ meta: { user_uuid } }) => `/profile/profiles/${user_uuid}/mute`,
  method: HttpMethods.Post
})

export const useUnmuteUserAction = createUseFetchAction<string, UserParams>({
  key: 'unmute-user',
  path: ({ meta: { user_uuid } }) => `/profile/profiles/${user_uuid}/unmute`,
  method: HttpMethods.Post
})

type TwitterAuth = {
  url: string
}

type TwitterAuthParams = {
  query: {
    callback_url: string
    source_url: string
  }
}

export const useTwitterAuthAction = createUseFetchAction<TwitterAuth, TwitterAuthParams>({
  key: 'twitter-auth',
  path: '/profile/providers/twitter/auth_url'
})

type TwitterDisconnect = {
  status: string
}

export const useTwitterDisconnectAction = createUseFetchAction<TwitterDisconnect>({
  key: 'twitter-disconnect',
  path: '/profile/providers/twitter/disconnect',
  method: HttpMethods.Post
})

export const useTwitterRecommend = createUseFetch<UserProfile[]>({
  key: 'twitter-recommendations',
  path: '/profile/recommend/twitter'
})

type AlertPreferences = {
  memo_alerts_enabled: boolean
  trade_alerts_enabled: boolean
}

type AlertPreferencesParams = {
  meta: { uuid: string }
  json: AlertPreferences
}

export const useUpdateAlertPreferencesAction = createUseFetchAction<AlertPreferences, AlertPreferencesParams>({
  key: 'alert-preferences',
  path: ({ meta: { uuid } }) => `/profile/profiles/${uuid}/alert-preferences`,
  method: HttpMethods.Post
})

type KnownFollowersPayload = {
  count: number
  profiles: Partial<UserProfile>[]
}

type KnownFollowersParams = {
  meta: {
    uuid: string
  }
}

export const useGetUsersKnownFollowers = createUseFetch<KnownFollowersPayload, KnownFollowersParams>({
  key: 'known-followers',
  path: ({ meta: { uuid } }) => `/profile/profiles/${uuid}/known-followers`
})

export const useGetBrokerFriends = createUseFetch<UserProfile[]>({
  key: 'broker-friends',
  path: '/profile/broker-friends'
})

type UserBanParams = {
  meta: {
    uuid: string
  }
}

type UserDeleteParams = {
  meta: {
    uuid: string
  }
}

export const useBanUser = createUseFetchAction<any, UserBanParams>({
  key: 'ban-user',
  method: HttpMethods.Post,
  path: params => `/identity/admin/${params.meta.uuid}/ban`
})

export const useUnbanUser = createUseFetchAction<any, UserBanParams>({
  key: 'unban-user',
  method: HttpMethods.Delete,
  path: params => `/identity/admin/${params.meta.uuid}/ban`
})

export const useDeleteUser = createUseFetchAction<any, UserDeleteParams>({
  key: 'delete-user',
  method: HttpMethods.Delete,
  path: params => `/identity/admin/${params.meta.uuid}/delete`
})

type UserBadgeParams = {
  json: {
    usernames: string[]
    badge_type: BadgeType
  }
}

export const useBadgeUsers = createUseFetchAction<any, UserBadgeParams>({
  key: 'badge-users',
  method: HttpMethods.Post,
  path: '/profile/profiles/badges'
})

export const useUnbadgeUsers = createUseFetchAction<any, UserBadgeParams>({
  key: 'unbadge-users',
  method: HttpMethods.Post,
  path: '/profile/profiles/badges_remove'
})
