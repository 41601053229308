import { useCallback, useEffect, useMemo, useRef } from 'react'
import { ChannelSettingsParams, useRedeemInviteToken } from '@commonstock/common/src/api/chat'
import { regroupChannels, useChannels } from '../chat/ChannelContext'
import useRefetchByKeys from '@commonstock/client/src/react/useRefetchByKeys'
import { useGetPendingRequests } from '@commonstock/common/src/api/profile'
import { isLoggedIn } from '@commonstock/common/src/auth'
import { useAuthTokens } from 'src/client/authenticator'
import config from 'src/config'
import { isServer } from 'src/utils/isServer'
import { Routes } from './constants'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import { Notification, NotificationStatus, useGetPagedNotifications } from '@commonstock/common/src/api/notification'
import { GlobalFeedPeriodType, GlobalFeedSortType } from '@commonstock/common/src/api/feed'
import { usePersistedState } from '@commonstock/common/src/utils/usePersistedState'
import { useRouter } from 'next/router'
import { UrlObject } from 'url'
import { GlobalFeedPeriodKey, GlobalFeedSortKey } from '../feed/constants'

export const useRedeemInvite = () => {
  const redeemInviteToken = useRedeemInviteToken()
  const { getUpdatedChannelAndSelect } = useChannels()

  const redeemInvite = useCallback(
    async (token: string, settings: ChannelSettingsParams, callback?: () => void) => {
      try {
        const inviteTokens = token.split(',')
        if (inviteTokens.length === 1) {
          const res = await redeemInviteToken({ json: { token, settings } })
          if (res.success) {
            await getUpdatedChannelAndSelect(res.success.payload.channel_url)
          }
        } else {
          await Promise.all(inviteTokens.map(async token => await redeemInviteToken({ json: { token, settings } })))
        }
        callback && callback()
      } catch (err) {
        alert(err?.fail?.payload?.error || 'An unexpected error occured')
      }
    },
    [redeemInviteToken, getUpdatedChannelAndSelect]
  )

  return redeemInvite
}

export function useNotificationsWebSocket(notificationCount: number) {
  let [tokens] = useAuthTokens()
  const clientRef = useRef<W3CWebSocket>()
  const match = !isServer && window.location.pathname === Routes.Notifications
  const refetchNotifications = useRefetchByKeys('paged-notifications', 'notifications')

  const countRef = useRef<number>(notificationCount)
  useEffect(() => {
    countRef.current = notificationCount
  }, [notificationCount])

  const onMessage = useCallback(() => {
    if (!countRef.current || match) refetchNotifications()
  }, [match, refetchNotifications])

  useEffect(() => {
    if (!tokens || clientRef.current) return

    const client = new W3CWebSocket(config.apiPaths.NotificationWS)

    client.onopen = () => client.send(tokens?.access || '')
    client.onclose = () => (clientRef.current = undefined)
    client.onmessage = onMessage

    clientRef.current = client
  }, [onMessage, tokens])

  useEffect(() => {
    return () => {
      if (clientRef.current) clientRef.current.close()
    }
  }, [])
}

export function useUnreadNotificationCount() {
  const { channels } = useChannels()
  const { invitedChannels } = regroupChannels(channels)
  const [pendingRequests] = useGetPendingRequests(null, { paused: !isLoggedIn() })
  const [notificationsPages] = useGetPagedNotifications(null, { paused: !isLoggedIn() })

  const notifications: Notification[] | undefined = notificationsPages?.flatMap(a => a.notifications)
  const unreadCount = notifications?.filter(n => n.status !== NotificationStatus.Read).length || 0

  const notificationCount = useMemo(
    () => (invitedChannels?.length || 0) + (pendingRequests?.length || 0) + unreadCount,
    [invitedChannels, pendingRequests, unreadCount]
  )

  return notificationCount
}

export function useGoto(url: UrlObject | string) {
  let router = useRouter()
  return useCallback(() => {
    // @ts-ignore not sure how to fix
    router.push(url)
  }, [router, url])
}

export function useGoToHome() {
  const [period, setPeriod, periodPending] = usePersistedState<GlobalFeedPeriodType>(
    GlobalFeedPeriodKey,
    GlobalFeedPeriodType.RECENT
  )
  const [sortBy, setSortBy, sortPending] = usePersistedState<GlobalFeedSortType>(
    GlobalFeedSortKey,
    GlobalFeedSortType.TOP
  )

  const router = useRouter()
  const isHome = !isServer && window.location.pathname === Routes.Home
  const homeLink: UrlObject = useMemo(() => ({ pathname: Routes.Home, query: { sort: sortBy, period } }), [
    period,
    sortBy
  ])

  const updateFeed = useRefetchByKeys('get-global-feed')

  const goToHome = useCallback(
    (e?: MouseEvent) => {
      if (e && 'preventDefault' in e) e.preventDefault()
      if (isHome) {
        updateFeed()
        router.push({ ...router, hash: 'refresh' })
      } else router.push(homeLink)
    },
    [homeLink, isHome, router, updateFeed]
  )

  return { period, setPeriod, periodPending, sortBy, setSortBy, sortPending, homeLink, goToHome }
}

export function useGoToFollowing() {
  const router = useRouter()
  const isHome = !isServer && window.location.pathname === Routes.Following
  const homeLink: UrlObject = useMemo(() => ({ pathname: Routes.Following }), [])

  const updateFeed = useRefetchByKeys('following-feed')

  const goToFollowing = useCallback(
    (e?: MouseEvent) => {
      if (e && 'preventDefault' in e) e.preventDefault()
      if (isHome) {
        updateFeed()
        router.push({ ...router, hash: 'refresh' })
      } else router.push(homeLink)
    },
    [homeLink, isHome, router, updateFeed]
  )

  return { goToFollowing }
}
